import React from "react";
import { H2, Divider, Box } from "@eriksdigital/atomic-ui";
import { Helmet } from "react-helmet-async";
import { T } from "../../components/Translation";

const CookieStatement = () => (
  <Box marginTop="sp24" marginBottom="sp24">
    <Helmet>
      <title>Guided Buying - Cookies</title>
    </Helmet>
    <H2>
      <T>title</T>
    </H2>
    <Box marginTop="sp16" marginBottom="sp16">
      <Divider />
    </Box>
    <Box id="cookiestatement" />
  </Box>
);

export default CookieStatement;
