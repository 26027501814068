import React, { useContext } from "react";
import { LanguageContext } from "@eriksdigital/atomic-ui";

export const formatMessage = (
  locales: { [key: string]: string } = {},
  message: string = "",
  rest: { [key: string]: string } = {}
): string => {
  if (Object.keys(locales).length === 0) return message;
  return Object.keys(rest).length
    ? Object.keys(rest).reduce((str, key) => {
        return str.replace(`{${key}}`, rest[key]);
      }, locales[message])
    : locales[message]
    ? locales[message]
    : message;
};

export const T: React.FC<{ children: string; [key: string]: string }> = ({
  children,
  ...rest
}) => {
  const { strings } = useContext(LanguageContext);

  if (Object.keys(strings).length === 0) return null;

  return Object.keys(rest).length
    ? Object.keys(rest).reduce((str: string, key: string) => {
        return str.replace(`{${key}}`, rest[key]);
      }, strings[children])
    : strings[children]
    ? strings[children]
    : children;
};
