import React, { useContext } from "react";
import { LanguageContext, Logo } from "@eriksdigital/atomic-ui";
import {
  HeaderWrapper,
  HeaderInnerWrapper,
  HeaderInner,
  LogoWrapper,
} from "./style";
import { ENVIRONMENT } from "../../constants";
import { constructUrl, EnvironmentsType } from "../../utils/shopUtils";

const Header = () => {
  const { language, erpSystem } = useContext(LanguageContext);
  return (
    <HeaderWrapper data-testid="header">
      <HeaderInnerWrapper>
        <HeaderInner>
          <LogoWrapper
            href={`${constructUrl(
              ENVIRONMENT as EnvironmentsType,
              erpSystem,
              language
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Logo size="auto" />
          </LogoWrapper>
        </HeaderInner>
      </HeaderInnerWrapper>
    </HeaderWrapper>
  );
};

export default Header;
